<template>
  <div class="share-list contain">
    <div
      v-for="item in list"
      :key="item.itemId"
      class="contact-div"
    >
      <div class="contact-dev-left">
        <img src="@/assets/img/icon_facebook.png">
      </div>
      <div class="contact-dev-right">
        <div class="contact-dev-right-top">
          <div class="contact-dev-right-top-top">
            {{ item.title }}
          </div>
          <a
            class="contact-dev-right-top-bottom firebaseClickEvent"
            :href="fbFailBack + item.fbId"
          >{{ item.link }}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// https://www.npmjs.com/package/callapp-lib
// import CallApp from 'callapp-lib'
export default {
  name: 'FbGroups',
  metaInfo: {
    title: 'Facebook Groupp', // set a title
    meta: [
      // { name: 'keyWords', content: 'My Example App' }
    ],
    link: [
      // { rel: 'asstes', href: 'https://assets-cdn.github.com/' }
    ]
  },
  data () {
    return {
      fbFailBack: 'https://www.facebook.com/groups/',
      list: [
        {
          title: 'Facebook Global (English)',
          contentType: 'NetBoomUSA',
          itemId: 'NetBoomUSA',
          fbId: 'NetBoom.USA/',
          link: 'https://bit.ly/3h4qoFd'
        },
        {
          title: 'Netboom Thailand ( ไทย )',
          contentType: 'NetBoomThailand',
          itemId: 'NetBoomThailand',
          fbId: '876076196191131',
          link: 'https://bit.ly/2WcYqit'
        },
        {
          title: 'Netboom Indonesia (bahasa Indonesia)',
          contentType: 'NetBoomIndonesia',
          itemId: 'NetBoomIndonesia',
          fbId: '2451291648304101',
          link: 'https://bit.ly/3doIvn9'
        }
        // {
        //   title: 'NetBoom League （English/中文）',
        //   contentType: 'NetBoomLeague',
        //   itemId: 'NetBoomLeague',
        //   fbId: '2538726006358801',
        //   link: 'http://bit.ly/netboomFBgroup'
        // }
      ]
    }
  },
  methods: {
    // handleCallPpp (item) {
    //   const fbFailBack = 'https://www.facebook.com/groups/' + item.fbId
    //   const openPath = 'group/' + item.fbId
    //   const option = {
    //     scheme: {
    //       protocol: 'fb'
    //     },
    //     fallback: fbFailBack,
    //     timeout: 2000
    //   }
    //   const lib = new CallApp(option)
    //   lib.open({
    //     path: openPath
    //   })
    // }
  }
}
</script>

<style lang="less" >
  @import "~@/assets/styles/share-list.less";
  .contain{
    background-color: #F4F4F4;
  }
</style>
